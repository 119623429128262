import { useEffect } from 'react';
import { Box, Card, Container, Typography, Grid  } from '@mui/material';
import { GuestGuard } from '../../components/authentication/guest-guard';
import { JWTLogin } from '../../components/authentication/jwt-login';
import { useAuth } from '../../hooks/use-auth';
import { gtm } from '../../lib/gtm';

const Login = () => {
  const { platform } = useAuth();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          sx={{
            py: {
              xs: '65px',
              md: '140px'
            }
          }}
        >
          {process.env.REACT_APP_HAS_LOGIN_UP == 'true' && (
            <Box
              component="img"
              sx={{
                height: 80,
                m: 2,
                mx: 46
              }}
              alt="logo"
              src={process.env.REACT_APP_LOGIN_UP}
            />
          )}
          <Grid
            container
          >
            <Grid
              item
              md={4}
            >
              <Card
                sx={{ p: 4, mt: 2, backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }}
              >
                <Box
                  component="img"
                  sx={{
                      height: '42vh',
                      width: '25vh',
                      m: 0.7,
                      mx: 4
                  }}
                  alt="pauline"
                  src={process.env.REACT_APP_LOGIN_LEFT}
                />
                  <Typography
                    color="#FFFFFF"
                    sx={{ mt: 2, mx: 5 }}
                    variant="h6"
                  >
                    Utilisez votre compte {process.env.REACT_APP_COMPANY_NAME}
                  </Typography>
              </Card>
            </Grid>
          <Grid
            item
            md={6.5}
          >
            <Card
              elevation={20}
              sx={{ p: 4, mt: 2 }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h2"
                  sx={{ mt: 2}}>
                  Connexion
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  p: 3,
                  mt: -3
                }}
              >
                {platform === 'JWT' && <JWTLogin />}
              </Box>
            </Card>
          </Grid>
        </Grid>
        </Container>
      </Box>
    </>
  );
};

Login.getLayout = (page) => (
  <GuestGuard>
    {page}
  </GuestGuard>
);

export default Login;
